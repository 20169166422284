<template>
  <ReflectLink
    :link="`${path}`"
    class="item"
    :class="isActive && 'item--selected'"
  >
    <button
      :id="label"
      class="item__button"
    >
      <i
        class="item__icon"
        :class="`icon-${icon}`"
      />
      <span
        v-if="showTitle"
        class="item__label"
      >
        {{ $t(`menu.${label}`) }}
      </span>
    </button>
  </ReflectLink>
</template>

<script lang="ts">
export default {
  name: 'SideMenuItem',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: false
    },
    isActive: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
$button-height: 64px;
$button-width: 64px;

.item {
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $button-height;
    width: $button-width;
    color: $link-secondary;
    transition: all $short-transition;
    cursor: inherit;
  }

  &__label {
    @include font-text;
    @include font-size($font-size-tiny);
    margin-top: $margin-regular;
  }

  &__icon {
    @include font-icon;
    @include font-size(40px, 0);
  }

  &--selected {
    .item__button {
      color: $link-primary;
    }
  }

  &:hover {
    .item {
      &__button {
        transform: scale(1.1);
      }
    }
  }
}
</style>
