import { GET_COMPANY_QUERY } from '~/gql/company/getCompanyQuery'

interface CompanyInfo {
  name: string
  customLogoutUrl: string | null
}

type State = CompanyInfo

export const useUserCompanyStore = defineStore('userCompany', {
  state: (): State => ({
    name: '',
    customLogoutUrl: null
  }),
  actions: {
    async loadCompanyInfo() {
      if (this.name.length > 0) return
      const app = useNuxtApp()
      const data = (
        await app.$apiGqlClient.query({
          query: GET_COMPANY_QUERY
        })
      ).data.company as CompanyInfo

      this.name = data.name
      this.customLogoutUrl = data.customLogoutUrl
    }
  }
})
