import { gql } from 'graphql-tag'

export const FETCH_CUSTOMIZATIONS_QUERY = gql`
  query FETCH_CUSTOMIZATIONS_QUERY {
    getCustomizations {
      field
      value
    }
  }
`
