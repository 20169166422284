<template>
  <nav class="menu">
    <Transition
      name="fade"
      mode="out-in"
    >
      <ReflectLink
        v-if="!empty"
        :link="ROUTE.DASHBOARD"
      >
        <img
          src="~/assets/images/logo.png"
          draggable="false"
          class="menu__logo"
        />
      </ReflectLink>
    </Transition>

    <Transition
      name="fade"
      mode="out-in"
    >
      <div v-if="!empty">
        <SideMenuTooltip
          v-for="item in allowedMenuItems"
          :key="item.value"
          :text="$t(`menu.${item.value}`)"
          class="menu__item"
        >
          <SideMenuItem
            :label="item.value"
            :icon="item.icon"
            :path="item.path"
            :is-active="isMenuItemActive(item.path)"
          />
        </SideMenuTooltip>
      </div>
    </Transition>
  </nav>
</template>

<script lang="ts">
import { ROUTE } from '~/constants/routes'
import { useCurrentUserStore } from '~/stores/currentUser'
import { hasUserAccessToPage } from '~/services/router'
import { UserRole } from '~/types/permissions'

export default {
  name: 'SideMenu',
  props: {
    empty: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return { ROUTE }
  },
  computed: {
    canAccessBenchmark() {
      return useCurrentUserStore().canAccessBenchmark
    },
    user() {
      return useCurrentUserStore()
    },
    menuItems() {
      const settingsPath =
        this.user.role === UserRole.ADMIN
          ? ROUTE.SETTINGS
          : ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES

      return [
        {
          value: 'home',
          icon: 'home',
          path: ROUTE.DASHBOARD,
          shouldDisplay: hasUserAccessToPage(this.user, ROUTE.DASHBOARD)
        },
        {
          value: 'legalReports',
          icon: 'balance',
          path: ROUTE.LEGAL_REPORTS,
          shouldDisplay: hasUserAccessToPage(this.user, ROUTE.LEGAL_REPORTS)
        },
        {
          value: 'explore',
          icon: 'bar',
          path: ROUTE.EXPLORE,
          shouldDisplay: hasUserAccessToPage(this.user, ROUTE.EXPLORE)
        },
        {
          value: 'benchmark',
          icon: 'bench',
          path: ROUTE.BENCHMARK,
          shouldDisplay:
            this.canAccessBenchmark &&
            hasUserAccessToPage(this.user, ROUTE.BENCHMARK)
        },
        {
          value: 'settings',
          icon: 'settings',
          path: settingsPath,
          shouldDisplay: hasUserAccessToPage(this.user, settingsPath)
        }
      ]
    },
    allowedMenuItems() {
      return this.menuItems.filter(menuItem => menuItem.shouldDisplay)
    }
  },
  methods: {
    isMenuItemActive(itemPath: string) {
      return this.$route.path.startsWith(itemPath)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg-secondary;
  box-sizing: border-box;
  padding: $margin-regular;
  @include block-shadow;

  &__logo {
    position: absolute;
    top: $margin-regular;
    left: $margin-regular;
    right: $margin-regular;
    height: 48px;
    width: 48px;
  }

  &__item {
    cursor: pointer;

    &:first-of-type {
      margin-top: 184px;
    }

    &:not(:last-of-type) {
      margin-bottom: $margin-regular;
    }
  }
}
</style>
