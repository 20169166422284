import { SourceType } from './sources'
import type { BdeseAccessType } from './bdese'
import type { SourceAccessType } from './sources'

export enum PermissionDataCategory {
  PERSONAL_INFO = 'PERSONAL_INFO',
  GENDER = 'GENDER',
  CONTRACT = 'CONTRACT',
  TRIAL_PERIOD = 'TRIAL_PERIOD',
  COMPENSATION = 'COMPENSATION',
  PAY_RAISE = 'PAY_RAISE',
  CAREER = 'CAREER',
  ABSENTEEISM = 'ABSENTEEISM',
  DEPARTURE = 'DEPARTURE',
  DEPARTURE_REASON = 'DEPARTURE_REASON',
  RECRUITMENT = 'RECRUITMENT',
  EGAPRO = 'EGAPRO'
}

export enum PermissionLevel {
  OVERVIEW = 'OVERVIEW',
  FULL_ACCESS = 'FULL_ACCESS'
}

export enum ScopeType {
  COMPANY = 'COMPANY',
  USER_SCOPE = 'USER_SCOPE'
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  HR = 'HR',
  FINANCE = 'FINANCE',
  RECRUITER = 'RECRUITER',
  NONE = 'NONE',
  EMPLOYEE = 'EMPLOYEE'
}

export interface Permission {
  role: UserRole
  scopeType: ScopeType
  accessType: PermissionLevel | null
  key: PermissionDataCategory
}

export interface DataSensitivityCategoryInfoContent {
  title: string
  source: SourceType
  metrics: string
  dimensions: string
}

export interface AccessPermission<
  T extends SourceAccessType | BdeseAccessType
> {
  email: string
  role: UserRole
  accessType: T | null
}

export enum PermissionContext {
  SOURCE = 'SOURCE',
  BDESE = 'BDESE'
}
