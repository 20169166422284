<template>
  <Bar
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
  />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import exportedColors from '~/assets/style/_exportTrendEvolutionColors.module.scss'

const BORDER_RADIUS = 20

export default {
  name: 'TrendEvolution',
  colors: Object.values(exportedColors),
  components: { Bar },
  mixins: [chartMixin],
  methods: {
    generateCustomChartData(chartData) {
      const customChartData = {
        ...chartData,
        datasets: chartData.datasets.map(dataset => {
          const pastDataColor = {
            bottom: this.$options.colors[0],
            top: this.$options.colors[1]
          }

          const futureDataColor = {
            bottom: this.$options.colors[2],
            top: this.$options.colors[3]
          }

          return {
            ...dataset,
            yAxisID: 'y',
            barPercentage: 1,
            categoryPercentage: 1,
            barThickness: 6,
            backgroundColor: context => {
              const chart = context.chart
              const { ctx, chartArea } = chart
              if (!chartArea) {
                return
              }
              this.generateGradientColor(ctx, chartArea, 0, pastDataColor)
              this.generateGradientColor(ctx, chartArea, 1, futureDataColor)
              return chartData.isInFuture.map(isInFuture =>
                isInFuture ? this.chartGradients[1] : this.chartGradients[0]
              )
            },
            borderRadius: {
              topLeft: BORDER_RADIUS,
              topRight: BORDER_RADIUS,
              bottomLeft: BORDER_RADIUS,
              bottomRight: BORDER_RADIUS
            },
            borderSkipped: false
          }
        })
      }

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        scales: this.getHiddenScales(true),
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      }
    }
  }
}
</script>
