// Inspired by https://github.com/nuxt-community/sentry-module/issues/530#issuecomment-1570407408

import {
  HttpClient as HttpClientIntegration,
  ReportingObserver as ReportingObserverIntegration
} from '@sentry/integrations'
import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import { withScope } from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin({
  parallel: true,
  setup: nuxtApp => {
    if (
      typeof window === 'undefined' ||
      !['true', true].includes(nuxtApp.$config.public.SENTRY_ENABLED)
    ) {
      return {
        provide: {
          sentrySetContext: (
            _name: string,
            _context: {
              [key: string]: any
            } | null
          ) => {},
          sentrySetUser: (_user: User | null) => {},
          sentrySetTag: (_key: string, _value: Primitive) => {},
          sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
          sentryCaptureException: (
            _exception: any,
            _captureContext?: CaptureContext
          ) => {}
        }
      }
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.SENTRY_DSN,
      environment: nuxtApp.$config.public.ENV,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            nuxtApp.$router as Router,
            {
              routeLabel: 'path'
            }
          )
        }),
        new Sentry.Replay({
          networkDetailAllowUrls: [nuxtApp.$config.public.BASE_URL]
        }),
        new HttpClientIntegration(),
        new ReportingObserverIntegration()
      ],
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1
    })

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope(scope => {
        scope.setExtra('context', context)
        Sentry.captureException(err)
      })
    }

    nuxtApp.hook('app:error', err => {
      // Ignore page 404 errors, if we had the sentry business version we could filter this kind of errors inside sentry
      if (
        err &&
        err.statusCode === 404 &&
        err.message &&
        err.message.includes('Page not found')
      ) {
        return
      }

      Sentry.captureException(err)
    })

    return {
      provide: {
        sentrySetContext: (name: any, context: any) => {
          Sentry.setContext(name, context)
        },
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: (exception: any, context: any) => {
          Sentry.captureException(exception, context)
        }
      }
    }
  }
})
