import { AnalyticsBrowser } from '@june-so/analytics-next'

export default defineNuxtPlugin(async nuxtApp => {
  const [analytics] = await AnalyticsBrowser.load({
    writeKey: nuxtApp.$config.public.JUNE_WRITE_KEY
  })

  return {
    provide: {
      june:
        nuxtApp.$config.public.ENV === 'prod'
          ? (analytics as unknown as AnalyticsBrowser)
          : {
              identify: (l: any, p: any) => console.log('JUNE_IDENTIFY', l, p),
              track: (l: any, p: any) => console.log('JUNE_TRACK', l, p),
              page: (l: any, p: any) => console.log('JUNE_PAGE', l, p)
            }
    }
  }
})
