<template>
  <NuxtLink
    v-if="!isAbsoluteLink"
    :target="target"
    :to="redirectLink"
    class="link"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="link"
    :target="target"
    :rel="rel"
    class="link"
  >
    <slot />
  </a>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ReflectLink',
  props: {
    link: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '_self'
    },
    rel: {
      type: String,
      default: ''
    },
    isAbsoluteLink: {
      default: false,
      type: Boolean
    },
    append: {
      default: false,
      type: Boolean
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>,
  computed: {
    redirectLink() {
      if (!this.append) {
        return this.link
      } else {
        const route = useRoute()
        return route.path + '/' + this.link
      }
    }
  }
}
</script>

<style>
.link {
  display: block;
  cursor: pointer;
  /* stylelint-disable */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  /* stylelint-enable */
}
</style>
