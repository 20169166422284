<template>
  <div class="error">
    {{ JSON.stringify(error) }}
  </div>
</template>

<script lang="ts">
import type { NuxtError } from '@nuxt/types'
import { ROUTE } from '~/constants/routes'

export default {
  name: 'Error',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: undefined
    }
  },
  created() {
    // This will redirect users to the root page on error
    const router = useRouter()
    const route = useRoute()
    router.replace({
      path: ROUTE.ROOT,
      query: {
        ...route.query
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.error {
  position: absolute;
  height: 100%;
  margin: auto;
}
</style>
