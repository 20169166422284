export default defineComponent({
  data(): { isMobileOrTablet: boolean } {
    return {
      isMobileOrTablet: false
    }
  },
  created() {
    this.isMobileOrTablet = this.computeIsMobileOrTablet()
  },
  mounted() {
    window.addEventListener('resize', this.refreshIsMobileOrTablet)
  },
  unmounted() {
    window.removeEventListener('resize', this.refreshIsMobileOrTablet)
  },
  methods: {
    refreshIsMobileOrTablet() {
      this.isMobileOrTablet = this.computeIsMobileOrTablet()
    },
    computeIsMobileOrTablet() {
      // This breakpoint matches the one defined in _variables.scss file
      if (window.innerWidth <= 954) {
        return true
      }
      return false
    }
  }
})
