interface State {
  theme: string
  isEditing: boolean
}
export const useLayoutStore = defineStore('layout', {
  state: (): State => ({
    theme: 'default',
    isEditing: false
  }),
  actions: {
    setTheme(theme: string) {
      this.theme = theme
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing
    }
  }
})
