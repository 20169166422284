<template>
  <div class="announcement">
    <div class="announcement__message">
      <p class="message__title">
        {{ $t('dashboard.announcement.title') }}
      </p>
      <p class="message__caption">
        {{ $t('dashboard.announcement.caption') }}
      </p>
    </div>
    <img
      class="announcement__image"
      src="~/assets/images/announcement.png"
      alt="Feature preview"
    />
    <ReflectButton
      theme="secondary"
      class="announcement__button"
      @click="goToBenchmarkPreview"
    >
      {{ $t('dashboard.announcement.button') }}
    </ReflectButton>
    <i
      class="icon-cross announcement__close"
      @click="$emit('dismiss')"
    />
  </div>
</template>

<script lang="ts">
import { formatDate2 } from '~/services/date'
import { ROUTE } from '~/constants/routes'
import { TRACKING_EVENTS } from '~/constants/tracking'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

export default {
  name: 'Announcement',
  emits: ['dismiss'],
  methods: {
    goToBenchmarkPreview() {
      const start = '2022-01-01'
      const end = '2022-12-31'
      this.$router.push({
        path: ROUTE.DASHBOARD + '/retention/benchmark/attrition',
        query: {
          [QUERY_PARAMETERS.START_DATE]: start,
          [QUERY_PARAMETERS.END_DATE]: end
        }
      })
      const startDate = formatDate2(start)
      const endDate = formatDate2(end)

      const { setSelectedPeriodFromDateRange } = useTimeDimensionStore()
      setSelectedPeriodFromDateRange({
        dateRange: [startDate, endDate],
        shouldModifyUrlParams: false
      })

      this.$june.track(TRACKING_EVENTS.OPEN_ANNOUNCEMENT_BENCHMARK, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.announcement {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $margin-intermediate 80px $margin-intermediate 40px;
  background: $announcement-banner-gradient;
  border-radius: 12px;

  .announcement {
    &__message {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: $text-inverse;
      width: 60%;

      .message {
        &__title {
          @include font-text($font-weight-book);
          @include font-size($font-size-regular);
        }

        &__caption {
          @include font-text;
          @include font-size($font-size-mini);
        }
      }
    }

    &__image {
      position: absolute;
      height: 74px;
      bottom: 0;
      left: 70%;
      transform: translateX(-50%);
    }

    &__button {
      margin-left: $margin-intermediate;
    }

    &__close {
      position: absolute;
      right: 12px;
      top: 12px;
      @include font-icon;
      @include font-size(12px);
      color: $text-inverse;
      cursor: pointer;
    }
  }
}
</style>
