import { AvailableLocale } from '~/types/locale'

const LOCALE_COOKIE_NAME = 'i18n_redirected'

export const getAppLocale = () => {
  const cookieLocale = getCookie(LOCALE_COOKIE_NAME)

  const userLocale = cookieLocale
    ? getLocaleFromCookieOrNavigatorValue(cookieLocale)
    : getLocaleFromCookieOrNavigatorValue(
        navigator.languages.find(locale =>
          getLocaleFromCookieOrNavigatorValue(locale)
        ) || ''
      )

  const locale = userLocale || AvailableLocale.EN

  if (locale !== cookieLocale) {
    setCookie(LOCALE_COOKIE_NAME, locale)
  }

  return locale
}

export const changeLocale = (locale: AvailableLocale) => {
  if (locale !== getAppLocale()) {
    setCookie(LOCALE_COOKIE_NAME, locale, '/')
    reloadNuxtApp({ force: true })
  }
}

const getLocaleFromCookieOrNavigatorValue = (locale: string) => {
  const allLocales = Object.values(AvailableLocale)
  if (allLocales.find(existingLocale => existingLocale === locale)) {
    return locale
  }
  return allLocales.find(existingLocale => existingLocale.startsWith(locale))
}

const setCookie = (cookieName: string, cookieValue: string, path?: string) => {
  document.cookie = `${cookieName}=${cookieValue};${
    path ? `path=${path};` : ''
  }`
}

const getCookie = (cookieName: string) => {
  const name = cookieName + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]!
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
