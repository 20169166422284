<template>
  <div class="screen">
    <img
      src="~/assets/images/logo-large.png"
      draggable="false"
      class="screen__logo"
    />

    <p class="screen__tagline">
      {{ $t('home.tagline') }}
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'MobileDefaultScreen'
}
</script>

<style lang="scss" scoped>
.screen {
  position: relative;
  width: 100%;
  /* stylelint-disable unit-disallowed-list */
  height: 80vh;
  /* stylelint-enable unit-disallowed-list */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    margin-top: $margin-large;
    width: 260px;
  }

  &__tagline {
    margin-top: $margin-medium;
    @include font-text;
    @include font-size($font-size-intermediate);
    max-width: 240px;
    letter-spacing: 0.02em;
    text-align: center;
  }
}
</style>
