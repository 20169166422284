import validate from "/home/circleci/reflect-front/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45route_45global from "/home/circleci/reflect-front/middleware/01.route.global.ts";
import _02_45page_45access_45global from "/home/circleci/reflect-front/middleware/02.pageAccess.global.ts";
import _03_45theme_45global from "/home/circleci/reflect-front/middleware/03.theme.global.ts";
import _04_45june_45track_45page_45global from "/home/circleci/reflect-front/middleware/04.juneTrackPage.global.ts";
import manifest_45route_45rule from "/home/circleci/reflect-front/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45route_45global,
  _02_45page_45access_45global,
  _03_45theme_45global,
  _04_45june_45track_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticate: () => import("/home/circleci/reflect-front/middleware/authenticate.ts"),
  authenticated: () => import("/home/circleci/reflect-front/middleware/authenticated.ts")
}