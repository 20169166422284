<template>
  <div class="tooltip-container">
    <slot />
    <span class="tooltip-container__content">
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'SideMenuTooltip',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>
}
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container__content {
  position: absolute;
  top: 50%;
  left: calc(100% + 4px);
  transform: translateY(-50%);
  background-color: $bg-tertiary;
  color: $text-inverse;
  padding: 4px 8px;
  border-radius: 4px;
  @include font-text;
  @include font-size($font-size-mini);
  opacity: 0;
  visibility: hidden;
  transition: opacity $short-transition, visibility $short-transition;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 8px solid $bg-tertiary;
  }
}

.tooltip-container:hover .tooltip-container__content {
  opacity: 1;
  visibility: visible;
}
</style>
