<template>
  <Doughnut
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="plugins"
  />
</template>

<script>
import 'chart.js/auto'
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import { legendSpacing } from '~/assets/chart/plugins'
import { EMPTY_VALUE } from '~/constants/cube'
import { formatChartValue } from '~/services/analyticsFormat'

const LEGEND_INCREASE_WIDTH = 56
const LEGEND_INCREASE_HEIGHT = 8
const LEGEND_INCREASE_MARGIN_TOP = 16

const LARGE_DATA_TRIM_LIMIT = 7

export default {
  name: 'TrendDistribution',
  components: { Doughnut },
  mixins: [chartMixin],
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    legendPosition: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    plugins() {
      const LegendSpacing = legendSpacing(
        this.legendPosition === 'right' ? LEGEND_INCREASE_WIDTH : 0,
        this.legendPosition === 'bottom' ? LEGEND_INCREASE_HEIGHT : 0,
        0,
        this.legendPosition === 'bottom' ? LEGEND_INCREASE_MARGIN_TOP : 0
      )
      return [LegendSpacing, ChartDataLabels]
    }
  },
  methods: {
    generateCustomChartData(chartData) {
      const length = chartData.datasets[0].data.filter(
        value => value !== null
      ).length
      const colors = this.getColorPalette(length)
      const customChartData = {
        ...chartData,
        datasets: chartData.datasets.map(dataset => {
          return {
            ...dataset,
            stack: undefined,
            backgroundColor: colors,
            borderWidth: 0,
            borderColor: colors,
            hoverBorderWidth: 0
          }
        })
      }

      this.removeZeroInData(customChartData, true)
      this.processLargeDataTrim(customChartData, LARGE_DATA_TRIM_LIMIT)

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        scales: this.getHiddenScales(),
        plugins: {
          legend: {
            display: true,
            position: this.legendPosition,
            align: this.legendPosition === 'bottom' ? 'start' : 'center',
            maxHeight: this.legendPosition === 'bottom' ? 120 : undefined,
            reverse: false,
            labels: {
              font: {
                size: 13
              },
              pointStyle: 'rectRounded',
              usePointStyle: true,
              filter: (item, chart) => {
                return !(
                  item.index > LARGE_DATA_TRIM_LIMIT - 1 &&
                  chart.labels[item.index] !== this.$t('cube.others')
                )
              }
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: true,
            callbacks: {
              label: context => {
                const label = context.label
                const format = context.dataset.format
                const value = context.parsed
                return `${label}: ${formatChartValue(value, format)}`
              }
            }
          },
          datalabels: {
            ...chartOptions.plugins.datalabels,
            color: '#FFFFFF',
            formatter: (value, context) => {
              const format = context.dataset.format
              return formatChartValue(value, format)
            }
          }
        }
      }
    },
    processLargeDataTrim(chartData, limit) {
      if (chartData.datasets[0].data.length > limit) {
        const indexOfNullValue = chartData.labels.indexOf(EMPTY_VALUE)

        let indexesToGroup = []
        if (indexOfNullValue === -1 || indexOfNullValue >= limit) {
          indexesToGroup = chartData.datasets[0].data
            .map((_, index) => index)
            .filter(index => index >= limit - 1)
        } else {
          indexesToGroup = [
            indexOfNullValue,
            ...chartData.datasets[0].data
              .map((_, index) => index)
              .filter(index => index >= limit)
          ]
        }

        if (indexesToGroup.length > 0) {
          const groupedValues = chartData.datasets[0].data
            .filter((_, i) => indexesToGroup.includes(i))
            .reduce((x, y) => x + y, 0)

          chartData.datasets[0].data = chartData.datasets[0].data.filter(
            (_, i) => !indexesToGroup.includes(i)
          )

          chartData.labels = chartData.labels.filter(
            (_, i) => !indexesToGroup.includes(i)
          )

          if (groupedValues > 0) {
            chartData.datasets[0].data.push(groupedValues)
            chartData.labels.push(this.$t('cube.others'))
          }
        }
      }
    }
  }
}
</script>
