import Toast from 'vue-toastification'
import '~/assets/style/plugins/vue-toastification.scss'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(Toast, {
    toastDefaults: {
      success: { icon: 'icon-success' },
      error: { icon: 'icon-cross' }
    },
    position: 'bottom-right',
    transition: 'Vue-Toastification__fade',
    timeout: 5000,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
    maxToasts: 5,
    newestOnTop: true
  })
})
