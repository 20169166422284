const ACCESS_TOKEN_KEY = 'auth.accessToken'

export default class AuthTokenStorer {
  static set(token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token)
  }

  static get() {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }

  static clear() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}
