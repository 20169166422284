<template>
  <div class="callout">
    <i class="icon-error-bench callout__icon" />
    <div class="callout__text">
      <p class="text__primary">
        {{ $t('benchmark.callout.primary') }}
      </p>
      <p class="text__secondary">
        {{ $t('benchmark.callout.secondary') }}
      </p>
    </div>
    <img
      class="callout__chart"
      src="~/assets/images/benchmark.png"
      alt="Benchmark chart"
    />
    <ReflectButton
      class="callout__button"
      @click="toggleSpreadTheWordModal"
    >
      {{ $t('benchmark.callout.button') }}
    </ReflectButton>
    <Teleport
      v-if="isSpreadTheWordModalOpen"
      to="#action"
    >
      <ReflectModal
        :title-message="$t('benchmark.callout.modal.title')"
        class="modal"
        @cancel="toggleSpreadTheWordModal"
      >
        <template #default>
          <div class="modal__container">
            <div class="container__header">
              {{ $t('benchmark.callout.modal.header') }}
            </div>
            <ReflectCopyField
              class="container__message"
              :value="$t('benchmark.callout.modal.message')"
              :multi-line="true"
            />
          </div>
        </template>
      </ReflectModal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { TRACKING_EVENTS } from '~/constants/tracking'

export default {
  name: 'Callout',
  data() {
    return {
      isSpreadTheWordModalOpen: false
    }
  },
  methods: {
    toggleSpreadTheWordModal() {
      this.isSpreadTheWordModalOpen = !this.isSpreadTheWordModalOpen
      if (this.isSpreadTheWordModalOpen) {
        this.$june.track(TRACKING_EVENTS.SPREAD_THE_WORD_BENCHMARK, {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.callout {
  display: flex;
  align-items: center;
  background-color: $color-benchmark-callout;
  border-radius: 4px;
  padding: $margin-intermediate;

  &__icon {
    @include font-icon;
    @include font-size(24px);
    color: $text-secondary;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: $margin-regular;

    .text {
      &__primary {
        @include font-text;
        @include font-size($font-size-regular);
        color: $text-primary;
      }

      &__secondary {
        @include font-text($font-weight-regular);
        @include font-size($font-size-mini);
        color: $text-quaternary;
      }
    }
  }

  &__chart {
    margin: 0 $margin-intermediate -20px $margin-intermediate;
    align-self: flex-end;
    width: 156px;
  }

  &__button {
    flex: 1;
    min-width: 120px;
  }
}

.modal {
  &__container {
    width: 452px;
    margin-bottom: $margin-medium;
  }

  .container {
    &__header {
      margin: 0 60px $margin-small 60px;
      text-align: center;
      @include font-text($font-weight-book);
      @include font-size($font-size-regular);
      color: $text-primary;
    }

    &__message {
      @include font-text($font-weight-book);
      @include font-size($font-size-regular);
    }
  }
}
</style>
