import { gql } from 'graphql-tag'

export const GET_COMPANY_QUERY = gql`
  query GET_COMPANY_QUERY {
    company {
      name
      customLogoutUrl
    }
  }
`
