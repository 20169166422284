<template>
  <Doughnut
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="$options.plugins"
    style="padding-bottom: 24px"
  />
</template>

<script>
import 'chart.js/auto'
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartMixin from '~/mixins/chartMixin'
import chartDrilldownMixin from '~/mixins/chartDrilldownMixin'
import chartOptions from '~/assets/chart/options'
import { legendSpacing } from '~/assets/chart/plugins'
import { formatChartValue } from '~/services/analyticsFormat'

const LEGEND_INCREASE_WIDTH = 280
const LEGEND_INCREASE_MARGIN_LEFT = 40
const LegendSpacing = legendSpacing(
  LEGEND_INCREASE_WIDTH,
  0,
  LEGEND_INCREASE_MARGIN_LEFT
)

export default {
  name: 'ChartDoughnut',
  components: { Doughnut },
  mixins: [chartMixin, chartDrilldownMixin],
  plugins: [LegendSpacing, ChartDataLabels],
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    legendPosition: {
      type: String,
      default: 'right'
    },
    showDatalabels: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    generateCustomChartData(chartData) {
      const length = chartData.datasets[0].data.filter(
        value => value !== null
      ).length
      const colors = this.getColorPalette(length)
      const customChartData = {
        ...chartData,
        labels: chartData.labels.map(label => label.formattedValue),
        datasets: chartData.datasets.map(dataset => {
          return {
            ...dataset,
            stack: undefined,
            backgroundColor: colors,
            borderColor: colors,
            hoverBorderColor: '#141644',
            hoverBorderWidth: 4
          }
        })
      }

      this.removeZeroInData(customChartData)
      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'default'
        },
        onClick: clickEvent => {
          this.openDrilldownFromElementClickEvent &&
            this.openDrilldownFromElementClickEvent(clickEvent)
        },
        scales: this.getHiddenScales(),
        plugins: {
          legend: {
            display: this.showLegend,
            position: this.legendPosition,
            reverse: false,
            labels: {
              font: {
                size: this.large ? 14 : 13
              },
              pointStyle: 'rectRounded',
              usePointStyle: true
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: this.showTooltip,
            callbacks: {
              title: context => {
                return context[0].label
              },
              label: context => {
                const label = context.dataset.label
                const format = context.dataset.format
                const value = context.parsed
                return `${label}: ${formatChartValue(value, format)}`
              }
            }
          },
          datalabels: {
            ...chartOptions.plugins.datalabels,
            color: this.showDatalabels ? '#FFFFFF' : 'transparent',
            formatter: (value, context) => {
              const format = context.dataset.format
              return formatChartValue(value, format)
            }
          }
        }
      }
    }
  }
}
</script>
