import { default as indexzgxfwD0kuTMeta } from "/home/circleci/reflect-front/pages/benchmark/index.vue?macro=true";
import { default as _91measure_93ebhzvyQmYyMeta } from "/home/circleci/reflect-front/pages/dashboard/absenteeism/benchmark/[measure].vue?macro=true";
import { default as _91measure_93tddoLgKyUAMeta } from "/home/circleci/reflect-front/pages/dashboard/absenteeism/explore/[measure].vue?macro=true";
import { default as indexBwQ18UMXCcMeta } from "/home/circleci/reflect-front/pages/dashboard/absenteeism/index.vue?macro=true";
import { default as absenteeismrRq52vatntMeta } from "/home/circleci/reflect-front/pages/dashboard/absenteeism.vue?macro=true";
import { default as _91measure_93V7V4eWoXv4Meta } from "/home/circleci/reflect-front/pages/dashboard/career/benchmark/[measure].vue?macro=true";
import { default as _91measure_93k3C3GnloikMeta } from "/home/circleci/reflect-front/pages/dashboard/career/explore/[measure].vue?macro=true";
import { default as indexQ5GjOVM2baMeta } from "/home/circleci/reflect-front/pages/dashboard/career/index.vue?macro=true";
import { default as careerzgLOggJIjdMeta } from "/home/circleci/reflect-front/pages/dashboard/career.vue?macro=true";
import { default as _91measure_93FEAAEaGqTTMeta } from "/home/circleci/reflect-front/pages/dashboard/dei/benchmark/[measure].vue?macro=true";
import { default as _91measure_93kKDXcEEWJDMeta } from "/home/circleci/reflect-front/pages/dashboard/dei/explore/[measure].vue?macro=true";
import { default as indexQxxpx0P1jhMeta } from "/home/circleci/reflect-front/pages/dashboard/dei/index.vue?macro=true";
import { default as dei3itNr3pp17Meta } from "/home/circleci/reflect-front/pages/dashboard/dei.vue?macro=true";
import { default as _91measure_93vXniWy9i0bMeta } from "/home/circleci/reflect-front/pages/dashboard/funnel/explore/[measure].vue?macro=true";
import { default as indexZeRtEexxTiMeta } from "/home/circleci/reflect-front/pages/dashboard/funnel/index.vue?macro=true";
import { default as funnelhFqSfbVY3nMeta } from "/home/circleci/reflect-front/pages/dashboard/funnel.vue?macro=true";
import { default as indexEqNkr6HvDCMeta } from "/home/circleci/reflect-front/pages/dashboard/index.vue?macro=true";
import { default as _91measure_93CUvit8fiufMeta } from "/home/circleci/reflect-front/pages/dashboard/jobs/explore/[measure].vue?macro=true";
import { default as index7IIdAsdcpDMeta } from "/home/circleci/reflect-front/pages/dashboard/jobs/index.vue?macro=true";
import { default as jobsH7GQNv3SYQMeta } from "/home/circleci/reflect-front/pages/dashboard/jobs.vue?macro=true";
import { default as _91measure_93fQwyqwXhdjMeta } from "/home/circleci/reflect-front/pages/dashboard/payroll/benchmark/[measure].vue?macro=true";
import { default as _91measure_931qnlVWVJaRMeta } from "/home/circleci/reflect-front/pages/dashboard/payroll/explore/[measure].vue?macro=true";
import { default as indexNwzLwVKI5XMeta } from "/home/circleci/reflect-front/pages/dashboard/payroll/index.vue?macro=true";
import { default as payroll1Fkx026hCxMeta } from "/home/circleci/reflect-front/pages/dashboard/payroll.vue?macro=true";
import { default as _91measure_93UYm3rVluqlMeta } from "/home/circleci/reflect-front/pages/dashboard/retention/benchmark/[measure].vue?macro=true";
import { default as _91measure_93Z5a4Xw86OUMeta } from "/home/circleci/reflect-front/pages/dashboard/retention/explore/[measure].vue?macro=true";
import { default as index6Nw3wHxMt1Meta } from "/home/circleci/reflect-front/pages/dashboard/retention/index.vue?macro=true";
import { default as retentionxRxtctsrbXMeta } from "/home/circleci/reflect-front/pages/dashboard/retention.vue?macro=true";
import { default as _91measure_930WBmip0iFrMeta } from "/home/circleci/reflect-front/pages/dashboard/salary/benchmark/[measure].vue?macro=true";
import { default as _91measure_93wrLjMM7JipMeta } from "/home/circleci/reflect-front/pages/dashboard/salary/explore/[measure].vue?macro=true";
import { default as indexWJPQUJPECQMeta } from "/home/circleci/reflect-front/pages/dashboard/salary/index.vue?macro=true";
import { default as salaryb6M9mXm2xGMeta } from "/home/circleci/reflect-front/pages/dashboard/salary.vue?macro=true";
import { default as _91measure_930PAHyX9zjEMeta } from "/home/circleci/reflect-front/pages/dashboard/sourcing/explore/[measure].vue?macro=true";
import { default as indexsBc5R75DYEMeta } from "/home/circleci/reflect-front/pages/dashboard/sourcing/index.vue?macro=true";
import { default as sourcingjn3xGLr7lTMeta } from "/home/circleci/reflect-front/pages/dashboard/sourcing.vue?macro=true";
import { default as _91measure_93IbknYBO3kVMeta } from "/home/circleci/reflect-front/pages/dashboard/speed/explore/[measure].vue?macro=true";
import { default as indexc0Ydw9Xa9JMeta } from "/home/circleci/reflect-front/pages/dashboard/speed/index.vue?macro=true";
import { default as speedmMrbCwTYcXMeta } from "/home/circleci/reflect-front/pages/dashboard/speed.vue?macro=true";
import { default as _91measure_93fZeHTRVIXjMeta } from "/home/circleci/reflect-front/pages/dashboard/workforce/benchmark/[measure].vue?macro=true";
import { default as _91measure_93QPbjBYIYWiMeta } from "/home/circleci/reflect-front/pages/dashboard/workforce/explore/[measure].vue?macro=true";
import { default as indexWAjZMzcnG3Meta } from "/home/circleci/reflect-front/pages/dashboard/workforce/index.vue?macro=true";
import { default as workforceSldhCsJESHMeta } from "/home/circleci/reflect-front/pages/dashboard/workforce.vue?macro=true";
import { default as indexsnYi8bbFmMMeta } from "/home/circleci/reflect-front/pages/explore/index.vue?macro=true";
import { default as indexYYpM2aFs9gMeta } from "/home/circleci/reflect-front/pages/index.vue?macro=true";
import { default as bdese_45preview5cmBxIBSJLMeta } from "/home/circleci/reflect-front/pages/legal-reports/bdese-preview.vue?macro=true";
import { default as editzJ7D8BU5AqMeta } from "/home/circleci/reflect-front/pages/legal-reports/bdese/edit.vue?macro=true";
import { default as index85yrkKIt49Meta } from "/home/circleci/reflect-front/pages/legal-reports/bdese/index.vue?macro=true";
import { default as bdeseBGyfsprA4JMeta } from "/home/circleci/reflect-front/pages/legal-reports/bdese.vue?macro=true";
import { default as egapro_45indexvOKR3DSLE0Meta } from "/home/circleci/reflect-front/pages/legal-reports/egapro-index.vue?macro=true";
import { default as indexZHnjBIMOqBMeta } from "/home/circleci/reflect-front/pages/legal-reports/index.vue?macro=true";
import { default as indexHyN35IpB1cMeta } from "/home/circleci/reflect-front/pages/login/index.vue?macro=true";
import { default as ssoKYroWS8zycMeta } from "/home/circleci/reflect-front/pages/login/sso.vue?macro=true";
import { default as _91email_936xHEBDagKuMeta } from "/home/circleci/reflect-front/pages/reset-password/change/[email].vue?macro=true";
import { default as indexcBFBuzoMndMeta } from "/home/circleci/reflect-front/pages/reset-password/index.vue?macro=true";
import { default as indexK5SAeq8IBVMeta } from "/home/circleci/reflect-front/pages/settings/data-health/index.vue?macro=true";
import { default as indexra9eR3D3K4Meta } from "/home/circleci/reflect-front/pages/settings/data-mapping/index.vue?macro=true";
import { default as indexYWywmgAyP3Meta } from "/home/circleci/reflect-front/pages/settings/data-normalization/index.vue?macro=true";
import { default as indexSgNQroCHreMeta } from "/home/circleci/reflect-front/pages/settings/index.vue?macro=true";
import { default as index4bqKTtyFqMMeta } from "/home/circleci/reflect-front/pages/settings/integrations/index.vue?macro=true";
import { default as _91sourceId_93Xqxb6Vh4sfMeta } from "/home/circleci/reflect-front/pages/settings/integrations/sources/[sourceId].vue?macro=true";
import { default as integrationsS6r8LFTf3YMeta } from "/home/circleci/reflect-front/pages/settings/integrations.vue?macro=true";
import { default as preferences6CoJN9CK1dMeta } from "/home/circleci/reflect-front/pages/settings/my-account/preferences.vue?macro=true";
import { default as profilewjwOprCpePMeta } from "/home/circleci/reflect-front/pages/settings/my-account/profile.vue?macro=true";
import { default as access_45rolescfwvH074KbMeta } from "/home/circleci/reflect-front/pages/settings/permissions/access-roles.vue?macro=true";
import { default as data_45sensitivityI393D5VQHMMeta } from "/home/circleci/reflect-front/pages/settings/permissions/data-sensitivity.vue?macro=true";
import { default as source_45ownershipaVM5FWAbupMeta } from "/home/circleci/reflect-front/pages/settings/permissions/source-ownership.vue?macro=true";
import { default as indexYZmQJ5xQ2oMeta } from "/home/circleci/reflect-front/pages/settings/users/index.vue?macro=true";
import { default as preferencesu15mKM698IMeta } from "/home/circleci/reflect-front/pages/settings/workspace/preferences.vue?macro=true";
import { default as renamingTfPLK27slFMeta } from "/home/circleci/reflect-front/pages/settings/workspace/renaming.vue?macro=true";
import { default as _91email_93rfBluS5gs1Meta } from "/home/circleci/reflect-front/pages/signup/confirm/[email].vue?macro=true";
import { default as indexqSwOaqIg8sMeta } from "/home/circleci/reflect-front/pages/signup/index.vue?macro=true";
export default [
  {
    name: "benchmark",
    path: "/benchmark",
    meta: indexzgxfwD0kuTMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/benchmark/index.vue").then(m => m.default || m)
  },
  {
    name: absenteeismrRq52vatntMeta?.name,
    path: "/dashboard/absenteeism",
    meta: absenteeismrRq52vatntMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/absenteeism.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-absenteeism-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93ebhzvyQmYyMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/absenteeism/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-absenteeism-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93tddoLgKyUAMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/absenteeism/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-absenteeism",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/absenteeism/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: careerzgLOggJIjdMeta?.name,
    path: "/dashboard/career",
    meta: careerzgLOggJIjdMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/career.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-career-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93V7V4eWoXv4Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/career/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-career-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93k3C3GnloikMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/career/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-career",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/career/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dei3itNr3pp17Meta?.name,
    path: "/dashboard/dei",
    meta: dei3itNr3pp17Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/dei.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-dei-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93FEAAEaGqTTMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/dei/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-dei-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93kKDXcEEWJDMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/dei/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-dei",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/dei/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: funnelhFqSfbVY3nMeta?.name,
    path: "/dashboard/funnel",
    meta: funnelhFqSfbVY3nMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/funnel.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-funnel-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93vXniWy9i0bMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/funnel/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-funnel",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/funnel/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexEqNkr6HvDCMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: jobsH7GQNv3SYQMeta?.name,
    path: "/dashboard/jobs",
    meta: jobsH7GQNv3SYQMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/jobs.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-jobs-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93CUvit8fiufMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/jobs/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/jobs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: payroll1Fkx026hCxMeta?.name,
    path: "/dashboard/payroll",
    meta: payroll1Fkx026hCxMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/payroll.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-payroll-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93fQwyqwXhdjMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/payroll/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payroll-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_931qnlVWVJaRMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/payroll/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payroll",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/payroll/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: retentionxRxtctsrbXMeta?.name,
    path: "/dashboard/retention",
    meta: retentionxRxtctsrbXMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/retention.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-retention-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93UYm3rVluqlMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/retention/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-retention-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93Z5a4Xw86OUMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/retention/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-retention",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/retention/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: salaryb6M9mXm2xGMeta?.name,
    path: "/dashboard/salary",
    meta: salaryb6M9mXm2xGMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/salary.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-salary-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_930WBmip0iFrMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/salary/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-salary-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93wrLjMM7JipMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/salary/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-salary",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/salary/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sourcingjn3xGLr7lTMeta?.name,
    path: "/dashboard/sourcing",
    meta: sourcingjn3xGLr7lTMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/sourcing.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-sourcing-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_930PAHyX9zjEMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/sourcing/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-sourcing",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/sourcing/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: speedmMrbCwTYcXMeta?.name,
    path: "/dashboard/speed",
    meta: speedmMrbCwTYcXMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/speed.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-speed-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93IbknYBO3kVMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/speed/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-speed",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/speed/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: workforceSldhCsJESHMeta?.name,
    path: "/dashboard/workforce",
    meta: workforceSldhCsJESHMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/workforce.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-workforce-benchmark-measure",
    path: "benchmark/:measure()",
    meta: _91measure_93fZeHTRVIXjMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/workforce/benchmark/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-workforce-explore-measure",
    path: "explore/:measure()",
    meta: _91measure_93QPbjBYIYWiMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/dashboard/workforce/explore/[measure].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-workforce",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/dashboard/workforce/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "explore",
    path: "/explore",
    meta: indexsnYi8bbFmMMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexYYpM2aFs9gMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-reports-bdese-preview",
    path: "/legal-reports/bdese-preview",
    meta: bdese_45preview5cmBxIBSJLMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/bdese-preview.vue").then(m => m.default || m)
  },
  {
    name: bdeseBGyfsprA4JMeta?.name,
    path: "/legal-reports/bdese",
    meta: bdeseBGyfsprA4JMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/bdese.vue").then(m => m.default || m),
    children: [
  {
    name: "legal-reports-bdese-edit",
    path: "edit",
    meta: editzJ7D8BU5AqMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/bdese/edit.vue").then(m => m.default || m)
  },
  {
    name: "legal-reports-bdese",
    path: "",
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/bdese/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "legal-reports-egapro-index",
    path: "/legal-reports/egapro-index",
    meta: egapro_45indexvOKR3DSLE0Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/egapro-index.vue").then(m => m.default || m)
  },
  {
    name: "legal-reports",
    path: "/legal-reports",
    meta: indexZHnjBIMOqBMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/legal-reports/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexHyN35IpB1cMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-sso",
    path: "/login/sso",
    meta: ssoKYroWS8zycMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/login/sso.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-change-email",
    path: "/reset-password/change/:email()",
    meta: _91email_936xHEBDagKuMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/reset-password/change/[email].vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexcBFBuzoMndMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-data-health",
    path: "/settings/data-health",
    meta: indexK5SAeq8IBVMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/data-health/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-data-mapping",
    path: "/settings/data-mapping",
    meta: indexra9eR3D3K4Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/data-mapping/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-data-normalization",
    path: "/settings/data-normalization",
    meta: indexYWywmgAyP3Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/data-normalization/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexSgNQroCHreMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsS6r8LFTf3YMeta?.name,
    path: "/settings/integrations",
    meta: integrationsS6r8LFTf3YMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/integrations.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-integrations",
    path: "",
    meta: index4bqKTtyFqMMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-integrations-sources-sourceId",
    path: "sources/:sourceId()",
    meta: _91sourceId_93Xqxb6Vh4sfMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/integrations/sources/[sourceId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-my-account-preferences",
    path: "/settings/my-account/preferences",
    meta: preferences6CoJN9CK1dMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/my-account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "settings-my-account-profile",
    path: "/settings/my-account/profile",
    meta: profilewjwOprCpePMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/my-account/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-permissions-access-roles",
    path: "/settings/permissions/access-roles",
    meta: access_45rolescfwvH074KbMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/permissions/access-roles.vue").then(m => m.default || m)
  },
  {
    name: "settings-permissions-data-sensitivity",
    path: "/settings/permissions/data-sensitivity",
    meta: data_45sensitivityI393D5VQHMMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/permissions/data-sensitivity.vue").then(m => m.default || m)
  },
  {
    name: "settings-permissions-source-ownership",
    path: "/settings/permissions/source-ownership",
    meta: source_45ownershipaVM5FWAbupMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/permissions/source-ownership.vue").then(m => m.default || m)
  },
  {
    name: "settings-users",
    path: "/settings/users",
    meta: indexYZmQJ5xQ2oMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace-preferences",
    path: "/settings/workspace/preferences",
    meta: preferencesu15mKM698IMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/workspace/preferences.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace-renaming",
    path: "/settings/workspace/renaming",
    meta: renamingTfPLK27slFMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/settings/workspace/renaming.vue").then(m => m.default || m)
  },
  {
    name: "signup-confirm-email",
    path: "/signup/confirm/:email()",
    meta: _91email_93rfBluS5gs1Meta || {},
    component: () => import("/home/circleci/reflect-front/pages/signup/confirm/[email].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexqSwOaqIg8sMeta || {},
    component: () => import("/home/circleci/reflect-front/pages/signup/index.vue").then(m => m.default || m)
  }
]