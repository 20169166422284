<template>
  <Bar
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
  />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'

const BORDER_RADIUS = 4

export default {
  name: 'ChartBar',
  components: { Bar },
  mixins: [chartMixin],
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    large: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    isMultiMeasures: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    generateCustomChartData(chartData) {
      const length = chartData.datasets.length
      const colors = this.getColorPalette(length)
      const customChartData = {
        ...chartData,
        labels: chartData.labels.map(label => label.formattedValue),
        datasets: chartData.datasets.map((dataset, i) => {
          const j =
            this.isMultiMeasures && this.isStacked && i % 2 === 1 ? i - 1 : i
          const color = colors[j > colors.length - 1 ? j % colors.length : j]

          return {
            ...dataset,
            yAxisID: 'y',
            stack: 'main',
            data: i % 2 === 1 ? dataset.data : dataset.data.map(k => -k),
            backgroundColor: color,
            borderRadius: {
              topLeft:
                !this.isStacked ||
                i === length - 1 ||
                (this.isMultiMeasures && i === length - 2)
                  ? BORDER_RADIUS
                  : 0,
              topRight:
                !this.isStacked ||
                i === length - 1 ||
                (this.isMultiMeasures && i === length - 2)
                  ? BORDER_RADIUS
                  : 0,
              bottomLeft:
                !this.isStacked || i === 0 || (this.isMultiMeasures && i === 1)
                  ? BORDER_RADIUS
                  : 0,
              bottomRight:
                !this.isStacked || i === 0 || (this.isMultiMeasures && i === 1)
                  ? BORDER_RADIUS
                  : 0
            }
          }
        })
      }

      this.removeZeroInData(customChartData)

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        indexAxis: 'y',
        scales: {
          x: {
            ...chartOptions.scales.x,
            stacked: false,
            ticks: {
              ...chartOptions.scales.x.ticks,
              font: {
                size: this.large ? 14 : 13
              },
              maxTicksLimit: 7,
              callback: value => {
                return value < 0 ? -value : value
              }
            },
            beginAtZero: true
          },
          y: {
            ...chartOptions.scales.y,
            stacked: true,
            ticks: {
              ...chartOptions.scales.y.ticks,
              maxTicksLimit: 20,
              autoSkip: false,
              font: {
                size: this.large ? 14 : 13
              }
            },
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            reverse: this.isStacked,
            labels: {
              font: {
                size: this.large ? 14 : 13
              },
              pointStyle: 'rectRounded',
              usePointStyle: true,
              filter: item => {
                return !(
                  this.isMultiMeasures &&
                  this.isStacked &&
                  item.datasetIndex % 2 === 1
                )
              }
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: this.showTooltip,
            callbacks: {
              label: context => {
                const value = context.parsed.x
                const positiveOnly = value < 0 ? -value : value
                let retStr = ''
                if (context.datasetIndex === 0) {
                  retStr += `${this.$t(
                    'dashboard.dei.icons.female'
                  )}: ${positiveOnly.toString()}`
                } else {
                  retStr += `${this.$t(
                    'dashboard.dei.icons.male'
                  )}: ${positiveOnly.toString()}`
                }
                return retStr
              }
            }
          }
        }
      }
    }
  }
}
</script>
