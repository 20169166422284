import { ApolloClient, InMemoryCache, from } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import AuthenticationService from '~/services/AuthenticationService'

export default defineNuxtPlugin(nuxtApp => {
  const httpLink = createHttpLink({
    uri: `${nuxtApp.$config.public.API_BASE_URL}/graphql`
  })

  const authLink = setContext((_, { headers }) => {
    const token = AuthenticationService.getAuthenticationToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const apolloClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })

  return {
    provide: {
      apiGqlClient: apolloProvider.defaultClient
    }
  }
})
