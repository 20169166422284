import { DataType } from '~/types/analytics'

export enum Dashboard {
  WORKFORCE = 'WORKFORCE',
  DIVERSITY = 'DIVERSITY',
  RETENTION = 'RETENTION',
  ABSENTEEISM = 'ABSENTEEISM',
  PAYROLL = 'PAYROLL',
  SALARY = 'SALARY',
  CAREER = 'CAREER',
  JOBS = 'JOBS',
  FUNNEL = 'FUNNEL',
  SOURCING = 'SOURCING',
  SPEED = 'SPEED'
}

export const HOME_MODULES_CONFIG_BY_ID: {
  [key in Dashboard]: { title: string; slug: string; dataType: DataType }
} = {
  [Dashboard.WORKFORCE]: {
    title: 'workforce',
    slug: 'dashboard/workforce',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.DIVERSITY]: {
    title: 'diversity',
    slug: 'dashboard/dei',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.RETENTION]: {
    title: 'retention',
    slug: 'dashboard/retention',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.ABSENTEEISM]: {
    title: 'absenteeism',
    slug: 'dashboard/absenteeism',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.PAYROLL]: {
    title: 'payroll',
    slug: 'dashboard/payroll',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.SALARY]: {
    title: 'salary',
    slug: 'dashboard/salary',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.CAREER]: {
    title: 'career',
    slug: 'dashboard/career',
    dataType: DataType.EMPLOYEE
  },
  [Dashboard.JOBS]: {
    title: 'jobs',
    slug: 'dashboard/jobs',
    dataType: DataType.RECRUITMENT
  },
  [Dashboard.FUNNEL]: {
    title: 'funnel',
    slug: 'dashboard/funnel',
    dataType: DataType.RECRUITMENT
  },
  [Dashboard.SOURCING]: {
    title: 'sourcing',
    slug: 'dashboard/sourcing',
    dataType: DataType.RECRUITMENT
  },
  [Dashboard.SPEED]: {
    title: 'speed',
    slug: 'dashboard/speed',
    dataType: DataType.RECRUITMENT
  }
}

export const MODULE_CATEGORIES = [
  {
    title: 'workforce',
    modules: [
      Dashboard.WORKFORCE,
      Dashboard.DIVERSITY,
      Dashboard.RETENTION,
      Dashboard.ABSENTEEISM
    ]
  },
  {
    title: 'employee',
    modules: [
      Dashboard.SALARY,
      Dashboard.PAYROLL,
      Dashboard.CAREER,
      Dashboard.RETENTION
    ]
  },
  {
    title: 'talent',
    modules: [
      Dashboard.JOBS,
      Dashboard.SPEED,
      Dashboard.FUNNEL,
      Dashboard.SOURCING
    ]
  }
]
