import JwtDecode from 'jwt-decode'
import AuthTokenStorer from './AuthTokenStorer'
import type { PartialCurrentUser } from '~/types/user'

export const AUTHENTICATION_STATUS = {
  ERROR: 'ERROR',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED'
}

export default class AuthenticationService {
  private static async apiFetch(slug: string, params: any) {
    const nuxtApp = useNuxtApp()
    const apiUrl = nuxtApp.$config.public.API_BASE_URL
    const token = AuthenticationService.getAuthenticationToken()

    return await $fetch(`${apiUrl}/${slug}`, {
      ...params,
      headers: [['Authorization', `Bearer ${token}`]]
    })
  }

  static async signUp(email: string, password: string) {
    await this.apiFetch('auth/user/create', {
      method: 'POST',
      body: { email, password }
    })
  }

  static async getLoginMethod(email: string, redirectURL?: string | undefined) {
    return (await this.apiFetch('auth/login-method', {
      params: { email, redirectURL }
    })) as {
      connectionType: string
      ssoUrl?: string
      shouldRedirectToResetPassword?: boolean
    }
  }

  static async checkSSOCode(code: string) {
    return (await this.apiFetch('auth/sso/code', {
      params: { code }
    })) as { access: string }
  }

  static async loginWithLoginPassword(email: string, password: string) {
    const { access } = (await this.apiFetch('auth/jwt/create', {
      method: 'POST',
      body: { email, password }
    })) as { access: string }
    // this hack is used to bypass browser security refusing cookie setting on non https domain
    // if (nuxtApp.$config.public.ENV === 'local') {
    //  document.cookie = `refresh_token=${refresh};`
    // }
    AuthTokenStorer.set(access)

    return (await this.apiFetch('auth/me', {})) as PartialCurrentUser
  }

  static async resendVerificationCode(email: string) {
    await this.apiFetch('auth/resend-code', {
      params: { email }
    })
  }

  static async confirmEmail(email: string, verificationCode: string) {
    await this.apiFetch('auth/verify-code', {
      params: { email, verificationCode }
    })
  }

  /**
   * Is user logged in
   */
  static isLoggedIn() {
    try {
      const accessToken = AuthTokenStorer.get()
      if (!accessToken)
        return {
          isLoggedIn: false,
          status: AUTHENTICATION_STATUS.TOKEN_EXPIRED
        }

      const { emailVerified, exp } = JwtDecode(accessToken) as {
        emailVerified: string
        exp: number
      }

      const currentDate = new Date()
      const isTokenExpired = exp * 1000 < currentDate.getTime()
      if (isTokenExpired) {
        return {
          isLoggedIn: false,
          status: AUTHENTICATION_STATUS.TOKEN_EXPIRED
        }
      }

      const isUserUnconfirmed = emailVerified !== 'CONFIRMED'
      if (isUserUnconfirmed) {
        return {
          isLoggedIn: false,
          status: AUTHENTICATION_STATUS.EMAIL_NOT_VERIFIED
        }
      }
      return { isLoggedIn: true }
    } catch {
      return { isLoggedIn: false, status: 'ERROR' }
    }
  }

  /**
   * Get the JWT from the localStorage
   */
  static getAuthenticationToken() {
    return AuthTokenStorer.get()
  }

  /**
   * Return to login afterwards
   */
  static logout() {
    AuthTokenStorer.clear()
    document.cookie = `refresh_token=;`
  }

  static async refreshAccessTokenUsingRefreshToken() {
    const { access } = (await this.apiFetch('auth/jwt/refresh', {
      method: 'POST',
      body: {
        withCredentials: true
      }
    })) as { access: string }
    AuthTokenStorer.set(access)
  }

  /**
   * Start forgot password flow
   */
  static async resetPassword(email: string) {
    await this.apiFetch('auth/reset-password/request-code', {
      params: { email }
    })
  }

  /**
   * Confirm new password
   */
  static async confirmPassword(
    email: string,
    password: string,
    verificationCode: string
  ) {
    await this.apiFetch('auth/reset-password/change', {
      method: 'POST',
      body: {
        email,
        password,
        verificationCode
      }
    })
  }
}
