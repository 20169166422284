import { Chart } from 'chart.js'

export default defineNuxtPlugin(() => {
  Chart.defaults.layout.padding = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  }

  Chart.defaults.font.family = 'reflect-text'
  Chart.defaults.font.size = 13
  Chart.defaults.color = '#1e1e3c'

  Chart.defaults.transitions.active!.animation.duration = 0
})
