interface State {
  hasUnsavedModification: boolean
  isRouteChangeConfirmationModalOpen: boolean
  nextRouteToConfirm: string | null
}

export const useRouterStore = defineStore('router', {
  state: (): State => ({
    hasUnsavedModification: false,
    isRouteChangeConfirmationModalOpen: false,
    nextRouteToConfirm: null
  }),
  actions: {
    validateRouteChange(nextRoute: string) {
      if (
        this.hasUnsavedModification &&
        !this.isRouteChangeConfirmationModalOpen
      ) {
        this.isRouteChangeConfirmationModalOpen = true
        this.nextRouteToConfirm = nextRoute
        return false
      } else if (this.isRouteChangeConfirmationModalOpen) {
        return false
      } else {
        return true
      }
    },
    setHasUnsavedModification(hasUnsavedModification: boolean) {
      this.hasUnsavedModification = hasUnsavedModification
    },
    confirmRouteChange() {
      this.isRouteChangeConfirmationModalOpen = false
      this.hasUnsavedModification = false

      const router = useRouter()
      router.replace(this.nextRouteToConfirm!)

      this.nextRouteToConfirm = null
    },
    abortRouteChange() {
      this.isRouteChangeConfirmationModalOpen = false
      this.nextRouteToConfirm = null
    }
  }
})
