import { type RouteLocationNormalized } from 'vue-router'
import { ROUTE } from '~/constants/routes'
import { useRouterStore } from '~/stores/router'

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length
}

const checkIfFromKeysAreInToKeys = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized
) => {
  const aKeys = Object.keys(from)
  const bKeys = Object.keys(to)

  return aKeys.every(aKey => bKeys.includes(aKey))
}

const doesOriginAndTargetHaveADifferentLayout = (
  origin: RouteLocationNormalized,
  target: RouteLocationNormalized
) => {
  try {
    if (origin.matched[0]!.meta.layout !== target.matched[0]!.meta.layout)
      return true
    return false
  } catch (error) {
    console.log('No matching route for origin or target')
    navigateTo(ROUTE.ROOT)
  }
}
export default defineNuxtRouteMiddleware((to, from) => {
  const { validateRouteChange } = useRouterStore()

  if (to.path !== from.path && !validateRouteChange(to.fullPath)) {
    return abortNavigation()
  }

  const shouldRemoveQueryParam = doesOriginAndTargetHaveADifferentLayout(
    from,
    to
  )

  if (shouldRemoveQueryParam) {
    navigateTo(to)
  } else if (!hasQueryParams(to) && hasQueryParams(from)) {
    return navigateTo({
      ...to,
      query: { ...from.query }
    })
  } else if (
    hasQueryParams(to) &&
    hasQueryParams(from) &&
    !checkIfFromKeysAreInToKeys(from, to)
  ) {
    return navigateTo({
      ...to,
      query: { ...from.query, ...to.query }
    })
  } else {
    navigateTo(to)
  }
})
