import { gql } from 'graphql-tag'

export const FETCH_RENAMINGS_QUERY = gql`
  query FETCH_RENAMINGS_QUERY {
    getRenamings {
      field
      naming
    }
  }
`
