<template>
  <Bar
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
  />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import exportedColors from '~/assets/style/_exportDefaultChartColors.module.scss'

const BORDER_RADIUS = 4

export default {
  name: 'TrendRatio',
  colors: Object.values(exportedColors),
  components: { Bar },
  mixins: [chartMixin],
  methods: {
    generateCustomChartData(chartData) {
      const colors = this.$options.colors
      const customChartData = {
        ...chartData,
        datasets: chartData.datasets.map((dataset, i) => {
          const color = colors[i]

          return {
            ...dataset,
            yAxisID: 'y',
            backgroundColor: color,
            borderRadius: {
              topLeft: BORDER_RADIUS,
              topRight: BORDER_RADIUS,
              bottomLeft: BORDER_RADIUS,
              bottomRight: BORDER_RADIUS
            },
            borderSkipped: false
          }
        })
      }

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        scales: this.getHiddenScales(true),
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      }
    }
  }
}
</script>
