import { ROUTE } from '~/constants/routes'
import { useCurrentUserStore } from '~/stores/currentUser'
import AuthenticationService from '~/services/AuthenticationService'
import { hasUserAccessToPage } from '~/services/router'
import { useRenamingStore } from '~/stores/renaming'
import { useCustomizationStore } from '~/stores/customization'
import { useUserCompanyStore } from '~/stores/userCompany'

export default defineNuxtRouteMiddleware(async to => {
  const { isLoggedIn } = AuthenticationService.isLoggedIn()
  if (!isLoggedIn) return

  const { id, loadCurrentUser } = useCurrentUserStore()
  if (!id) {
    await loadCurrentUser()

    const { loadCompanyInfo } = useUserCompanyStore()
    await loadCompanyInfo()

    const { loadRenamings } = useRenamingStore()
    await loadRenamings()

    const { loadCustomizations } = useCustomizationStore()
    await loadCustomizations()
  }

  const user = useCurrentUserStore()

  if (!hasUserAccessToPage(user, to.path)) {
    return navigateTo(ROUTE.ROOT)
  }
})
