<template>
  <Bar
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="$options.plugins"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import { legendSpacing } from '~/assets/chart/plugins'
import exportedColors from '~/assets/style/_exportDefaultChartColors.module.scss'
import { formatChartValue } from '~/services/analyticsFormat'

const LEGEND_INCREASE_WIDTH = 16
const LegendSpacing = legendSpacing(LEGEND_INCREASE_WIDTH)

export default {
  name: 'ChartHorizontalPipe',
  components: { Bar },
  mixins: [chartMixin],
  props: {
    legendPosition: {
      type: String,
      default: 'right'
    }
  },
  colors: Object.values(exportedColors),
  plugins: [LegendSpacing, ChartDataLabels],
  methods: {
    generateCustomChartData(chartData) {
      const colors = this.$options.colors
      const customChartData = {
        ...chartData,
        labels: chartData.labels.map(label => label.formattedValue),
        datasets: chartData.datasets.map((dataset, i) => {
          const color = colors[i]

          return {
            ...dataset,
            backgroundColor: color
          }
        })
      }

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 32,
            bottom: 8
          }
        },
        indexAxis: 'x',
        scales: this.getHiddenScales(true),
        plugins: {
          legend: {
            display: true,
            position: this.legendPosition,
            labels: {
              font: {
                size: 13
              },
              pointStyle: 'rectRounded',
              usePointStyle: true
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: true,
            callbacks: {
              label: context => {
                const label = context.dataset.label
                const format = context.dataset.format
                const value = context.dataset.data[0]
                return `${label}: ${formatChartValue(value, format)}`
              },
              title: () => {
                return null
              }
            }
          },
          datalabels: {
            ...chartOptions.plugins.datalabels,
            color: '#15162b',
            anchor: 'end',
            offset: 0,
            align: 'top',
            font: {
              weight: 'bold'
            },
            formatter: (value, context) => {
              return formatChartValue(value, context.dataset.format)
            }
          }
        }
      }
    }
  }
}
</script>
